<header>
  <div class="navbar shadow-sm">
    <div class="container d-flex justify-content-between align-items-center">
      <a href="#" class="navbar-brand d-flex align-items-center">
        <img src="/assets/images/logo.svg" alt="{{ 'logo' | translate }}">
        <span class="logo-text">les bibliothèques</span>
      </a>
      <div  class="menu-right">
        <!-- Single button -->
        <div class="btn-group pull-right top-head-dropdown" *ngIf="name !== ''">
          <button type="button" class="btn btn-default dropdown-toggle btn-darkBlue-bib btn-bib text-lGrey font-14" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="fas fa-user" ></span>{{ name }}
          </button>
          <ul class="dropdown-menu dropdown-menu-right toggle-menu-bib text-center">
            <li>
              <a class="hand-cursor font-12" (click)="logout()">{{ 'LOGOUT' | translate }} <i class='fas fa-sign-out-alt m-1'></i></a>
            </li>
          </ul>
        </div>
        <!-- Language Selector -->
        <!--<div class="lng-bib input-group ml-auto mr-2">
          <select class="btn-bib language-selector" (change)="changeLanguage($event)">
            <option value="fr">FR</option>
            <option value="en">EN</option>
          </select>
        </div>-->
      </div>
    </div>
  </div>
  <div class="banner">
    <div class="banner-content">
      <h1 class="text-white">{{ 'TITLE' | translate }}</h1>
    </div>
  </div>
</header>

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  constructor(private authService: AuthService, private router: Router) {}

  // Appel de la méthode de connexion
  async onLogin() {
    const isAuthenticated = await this.authService.authenticateAndSaveUserData();
    if (isAuthenticated) {
      window.location.href = '/';
    } else {
      console.log('Échec de l\'authentification');
    }
  }


}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  name = '';

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.isAuthenticated().subscribe(async isAuthenticated => {
      if (isAuthenticated) {
        try {
          const userInfo = await this.authService.getUserInfoFromLocalStorage();
          if (userInfo) {
            this.name = userInfo;
          }
          // Rediriger si nécessaire
          const redirectUrl = this.authService.redirectUrl || '/';
          this.router.navigate([redirectUrl]);
        } catch (error) {
          console.error('Erreur lors de la récupération des informations utilisateur', error);
        }
      }
    });
  }

  // Méthode de déconnexion
  logout(): void {
    this.authService.logout();
  }
}

<div class="container-fluid d-flex justify-content-center align-items-center">
  <div class="card text-center mt-5 p-5 mb-5">
    <div class="card-body">
      <!-- Icone ici -->
      <div class="maintenance-icon">
        <i class="fa fa-cog fa-spin" aria-hidden="true"></i>
      </div>
      <h1 class="card-title">{{ 'MAINTENANCE_TITLE' | translate }}</h1>
      <p class="card-text text-content custom-description font-18" [innerHTML]="'MAINTENANCE_DESCRIPTION' | translate">
      </p>
    </div>
  </div>
</div>

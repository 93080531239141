<header>
  <div class="navbar bib-navbar">
    <div class="container-fluid">
      <a href="#" class="navbar-brand d-flex float-left bib-logo">
        <img src="/assets/images/logo.svg" alt="{{ 'logo' | translate }}" class="pl-3">
        <span class="logo-text">| Dépôt en libre accès</span>
      </a>
      <div  class="menu-right float-right" *ngIf="name !== ''">
        <!-- Single button -->
        <div class="btn-group pull-right top-head-dropdown">
          <button type="button" class="btn btn-default dropdown-toggle btn-login-bib btn-bib text-lGrey pl-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="fas fa-user" ></span>{{ name }}
          </button>
          <ul class="dropdown-menu dropdown-menu-right toggle-menu-bib text-center">
            <li>
              <a class="hand-cursor" (click)="logout()">{{ 'LOGOUT' | translate }} <i class='fas fa-sign-out-alt m-1'></i></a>
            </li>
          </ul>
        </div>
        <!-- Language Selector -->
        <!--<div class="lng-bib input-group ml-auto mr-2">
          <select class="btn-bib language-selector" (change)="changeLanguage($event)">
            <option value="fr">FR</option>
            <option value="en">EN</option>
          </select>
        </div>-->
      </div>
    </div>
  </div>
</header>
